import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { useVideoContext } from "@src/context";
export default function useParticipants() {
  var _room$participants$va;

  var _useVideoContext = useVideoContext(),
      room = _useVideoContext.room;

  var _useState = useState(Array.from((_room$participants$va = room === null || room === void 0 ? void 0 : room.participants.values()) !== null && _room$participants$va !== void 0 ? _room$participants$va : [])),
      _useState2 = _slicedToArray(_useState, 2),
      participants = _useState2[0],
      setParticipants = _useState2[1];

  useEffect(function () {
    if (room) {
      var participantConnected = function participantConnected(participant) {
        return setParticipants(function (prevParticipants) {
          return [].concat(_toConsumableArray(prevParticipants), [participant]);
        });
      };

      var participantDisconnected = function participantDisconnected(participant) {
        return setParticipants(function (prevParticipants) {
          return prevParticipants.filter(function (p) {
            return p !== participant;
          });
        });
      };

      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      return function () {
        room.off("participantConnected", participantConnected);
        room.off("participantDisconnected", participantDisconnected);
      };
    }
  }, [room]);
  return participants;
}