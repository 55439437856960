import { useContext } from "react";
import { ChatClientContext } from "./index";
export default function useChatClientContext() {
  var context = useContext(ChatClientContext);

  if (!context) {
    throw new Error("useChatClientContext must be used within a ChatClientProvider");
  }

  return context;
}