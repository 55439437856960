import { useContext } from "react";
import { TrendsContext } from "./index";
export default function useTrendsContext() {
  var context = useContext(TrendsContext);

  if (!context) {
    throw new Error("useTrendsContext must be used within a TrendsProvider");
  }

  return context;
}