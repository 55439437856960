import { useContext } from "react";
import { AuthContext } from "./index";
export default function useAuthContext() {
  var context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }

  return context;
}