import { useContext } from "react";
import { UpcomingCallsContext } from "./index";
export default function useUpcomingCallsContext() {
  var context = useContext(UpcomingCallsContext);

  if (!context) {
    throw new Error("useUpcomingCallsContext must be used within a UpcomingCallsProvider");
  }

  return context;
}