import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
export default function useParticipantNetworkQualityLevel(participant) {
  var _useState = useState(participant.networkQualityLevel),
      _useState2 = _slicedToArray(_useState, 2),
      networkQualityLevel = _useState2[0],
      setNetworkQualityLevel = _useState2[1];

  useEffect(function () {
    var handleNewtorkQualityLevelChange = function handleNewtorkQualityLevelChange(newNetworkQualityLevel) {
      return setNetworkQualityLevel(newNetworkQualityLevel);
    };

    setNetworkQualityLevel(participant.networkQualityLevel);
    participant.on("networkQualityLevelChanged", handleNewtorkQualityLevelChange);
    return function () {
      participant.off("networkQualityLevelChanged", handleNewtorkQualityLevelChange);
    };
  }, [participant]);
  return networkQualityLevel;
}