import { createTheme } from "@material-ui/core/styles";
export var theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1400,
      xl: 1600
    }
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff"
    },
    primary: {
      main: "#01bdd0",
      light: "#aa44ff",
      dark: "#0f384c",
      contrastText: "#fff"
    },
    secondary: {
      main: "#0000cc",
      light: "#0000ff",
      dark: "#0000aa",
      contrastText: "#fff"
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    text: {
      primary: "rgba(0, 0, 0, 1)",
      secondary: "rgba(0, 0, 0, 0.7)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    }
  }
});