import { useContext } from "react";
import { BusinessDashboardContext } from "./index";
export default function useBusinessDashboardContext() {
  var context = useContext(BusinessDashboardContext);

  if (!context) {
    throw new Error("useBusinessDashboardContext must be used within a BusinessDashboardProvider");
  }

  return context;
}