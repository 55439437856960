import axios from "axios";
import { getAccessToken, removeToken } from "@utils/token";
import { removeItem } from "@utils/helper";
import { LOGIN_URL } from "@routes";
import { BASE_URL, TOKEN_BASE, AUTHORIZATION } from "./urls";
import { USER_KEY } from "../constants";
import { history } from "../history";
axios.defaults.baseURL = BASE_URL;
axios.interceptors.request.use(function (config) {
  var token = getAccessToken();

  if (token) {
    config.headers[AUTHORIZATION] = "".concat(TOKEN_BASE, " ").concat(token);
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  var _error$response;

  if ((error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 401) {
    removeItem(USER_KEY);
    removeToken();
    history.push(LOGIN_URL); // eslint-disable-next-line prefer-promise-reject-errors

    return Promise.reject(null);
  }

  return Promise.reject(error);
}); // axios.interceptors.response.use(
//   (response: AxiosResponse) => {
//     return response;
//   },
//   (error: AxiosError) => {
//     const originalRequest: any = error.config;
//     if (!error.response) {
//       /* eslint-disable prefer-promise-reject-errors */
//       return Promise.reject(NETWORK_ERROR);
//     }
//     if (
//       error.response.status === 401
//       // && originalRequest.url === `${BASE_URL}/${REFRESH_TOKEN_URL}`
//     ) {
//       removeToken();
//       removeItem(USER_KEY);
//       history.push(LOGIN_URL);
//       return Promise.reject(INVALID_TOKEN);
//     }
//     if (error.response.status === 401 && !originalRequest.__retry) {
//       originalRequest.__retry = true;
//       const body = {
//         refresh_token: getRefreshToken(),
//       };
//       return axios.post(REFRESH_TOKEN_URL, body).then((res: AxiosResponse) => {
//         if (res.status === 201) {
//           const { accessToken, refreshToken } = res.data;
//           setToken(accessToken, refreshToken);
//           axios.defaults.headers.common[
//             AUTHORIZATION
//           ] = `${TOKEN_BASE} ${accessToken}`;
//           return axios(originalRequest);
//         }
//       });
//     }
//     return Promise.reject(error);
//   }
// );