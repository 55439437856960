import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from "react";
export default function useVideoTrackDimensions(track) {
  var _useState = useState(track === null || track === void 0 ? void 0 : track.dimensions),
      _useState2 = _slicedToArray(_useState, 2),
      dimensions = _useState2[0],
      setDimensions = _useState2[1];

  useEffect(function () {
    setDimensions(track === null || track === void 0 ? void 0 : track.dimensions);

    if (track) {
      var handleDimensionsChanged = function handleDimensionsChanged(newTrack) {
        return setDimensions({
          width: newTrack.dimensions.width,
          height: newTrack.dimensions.height
        });
      };

      track.on("dimensionsChanged", handleDimensionsChanged);
      return function () {
        track.off("dimensionsChanged", handleDimensionsChanged);
      };
    }
  }, [track]);
  return dimensions;
}