import { useContext } from "react";
import { AllNotificationCountContext } from "./index";
export default function useAllNotificationCountContext() {
  var context = useContext(AllNotificationCountContext);

  if (!context) {
    throw new Error("useAllNotificationCountContext must be used within a AllNotificationCountProvider");
  }

  return context;
}