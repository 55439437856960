import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";

var useChatConnectionState = function useChatConnectionState(chatClient) {
  var _useState = useState("connecting"),
      _useState2 = _slicedToArray(_useState, 2),
      chatConnectionState = _useState2[0],
      setChatConnectionState = _useState2[1];

  useEffect(function () {
    var chatConnectionStateHandler = function chatConnectionStateHandler(state) {
      setChatConnectionState(state);
    };

    if (chatClient) {
      chatClient.on("connectionStateChanged", chatConnectionStateHandler);
    }

    return function () {
      chatClient === null || chatClient === void 0 ? void 0 : chatClient.off("connectionStateChanged", chatConnectionStateHandler);
    };
  }, [chatClient]);
  return {
    chatConnectionState: chatConnectionState
  };
};

export default useChatConnectionState;