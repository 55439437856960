import { useContext } from "react";
import { PatientInformationContext } from "./index";
export default function usePatientInformationContext() {
  var context = useContext(PatientInformationContext);

  if (!context) {
    throw new Error("usePatientInformationContext must be used within a PatientInformationProvider");
  }

  return context;
}