import { useContext } from "react";
import { ChatNotificationsContext } from "./index";
export default function useChatNotificationsContext() {
  var context = useContext(ChatNotificationsContext);

  if (!context) {
    throw new Error("useChatNotificationsContext must be used within a ChatNotificationsProvider");
  }

  return context;
}