import { useContext } from "react";
import { ErrorContext } from "./index";
export default function useErrorContext() {
  var context = useContext(ErrorContext);

  if (!context) {
    throw new Error("useErrorContext must be used within a ErrorProvider");
  }

  return context;
}