import { TimeFilterValue } from "@src/globalTypes";
import { isGlucoseGraph } from "@src/utils/helper";

var useTrends = function useTrends() {
  var onTimeFilterChangeLogicHandler = function onTimeFilterChangeLogicHandler(_ref) {
    var key = _ref.key,
        setTimeFilterValueFunc = _ref.setTimeFilterValueFunc,
        setTrendLoadingFunc = _ref.setTrendLoadingFunc,
        setReadingsFunc = _ref.setReadingsFunc,
        getFilteredReadingFunc = _ref.getFilteredReadingFunc,
        sixMonthsReadingsState = _ref.sixMonthsReadingsState,
        graphType = _ref.graphType,
        isMetabolicTrend = _ref.isMetabolicTrend;
    setTimeFilterValueFunc(TimeFilterValue[key]);
    setTrendLoadingFunc(true);
    var readings = [];

    if (isMetabolicTrend) {
      readings = getMetabolicFilteredReadingHandler(key, sixMonthsReadingsState, getFilteredReadingFunc);
    } else if (isGlucoseGraph(graphType)) {
      readings = getGlucoseFilteredReadingHandler(key, sixMonthsReadingsState, getFilteredReadingFunc);
    } else {
      readings = getFilteredReadingHandler(key, sixMonthsReadingsState, getFilteredReadingFunc);
    }

    setReadingsFunc(readings);
    setTrendLoadingFunc(false);
  };

  var getFilteredReadingHandler = function getFilteredReadingHandler(key, sixMonthsReadingsState, getFilteredReadingFunc) {
    var readings = [];

    if (key === 0) {
      readings = getFilteredReadingFunc(sixMonthsReadingsState, 7);
    } else if (key === 1) {
      readings = getFilteredReadingFunc(sixMonthsReadingsState, 30);
    } else if (key === 2) {
      readings = getFilteredReadingFunc(sixMonthsReadingsState, 3, "months");
    } else {
      readings = getFilteredReadingFunc(sixMonthsReadingsState, 0);
    }

    return readings;
  };

  var getMetabolicFilteredReadingHandler = function getMetabolicFilteredReadingHandler(key, sixMonthsReadingsState, getFilteredReadingFunc) {
    var readings = [];

    if (key === 0) {
      readings = getFilteredReadingFunc(sixMonthsReadingsState, 3);
    } else if (key === 1) {
      readings = getFilteredReadingFunc(sixMonthsReadingsState, 14);
    } else if (key === 2) {
      readings = getFilteredReadingFunc(sixMonthsReadingsState, 30);
    } else {
      readings = getFilteredReadingFunc(sixMonthsReadingsState, 0);
    }

    return readings;
  };

  var getGlucoseFilteredReadingHandler = function getGlucoseFilteredReadingHandler(key, sixMonthsReadingsState, getFilteredReadingFunc) {
    var readings = [];

    if (key === 0) {
      readings = getFilteredReadingFunc(sixMonthsReadingsState, 24, "hours");
    } else if (key === 1) {
      readings = getFilteredReadingFunc(sixMonthsReadingsState, 7);
    } else if (key === 2) {
      readings = getFilteredReadingFunc(sixMonthsReadingsState, 30);
    } else {
      readings = getFilteredReadingFunc(sixMonthsReadingsState, 3, "months");
    }

    return readings;
  };

  return {
    onTimeFilterChangeLogicHandler: onTimeFilterChangeLogicHandler,
    getFilteredReadingHandler: getFilteredReadingHandler,
    getGlucoseFilteredReadingHandler: getGlucoseFilteredReadingHandler
  };
};

export default useTrends;