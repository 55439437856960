import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useCallback, useEffect, useRef, useState } from "react";
import Video from "twilio-video";
import { isMobile } from "@utils/helper";
// @ts-ignore
window.TwilioVideo = Video;
export default function useRoom(localTracks, onError, options) {
  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      room = _useState2[0],
      setRoom = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isConnecting = _useState4[0],
      setIsConnecting = _useState4[1];

  var optionsRef = useRef(options);
  useEffect(function () {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);
  var connect = useCallback(function (token) {
    setIsConnecting(true);
    return Video.connect(token, _objectSpread(_objectSpread({}, optionsRef.current), {}, {
      tracks: localTracks,
      networkQuality: {
        local: 1,
        remote: 1
      }
    })).then(function (newRoom) {
      setRoom(newRoom);

      var disconnect = function disconnect() {
        return newRoom.disconnect();
      }; // This app can add up to 13 'participantDisconnected' listeners to the room object, which can trigger
      // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.


      newRoom.setMaxListeners(15);
      newRoom.once("disconnected", function () {
        // Reset the room only after all other `disconnected` listeners have been called.
        setTimeout(function () {
          return setRoom(null);
        });
        window.removeEventListener("beforeunload", disconnect);

        if (isMobile) {
          window.removeEventListener("pagehide", disconnect);
        }
      }); // @ts-ignore

      window.twilioRoom = newRoom;
      newRoom.localParticipant.videoTracks.forEach(function (publication) {
        return (// All video tracks are published with 'low' priority because the video track
          // that is displayed in the 'MainParticipant' component will have it's priority
          // set to 'high' via track.setPriority()
          publication.setPriority("low")
        );
      });
      setIsConnecting(false); // Add a listener to disconnect from the room when a user closes their browser

      window.addEventListener("beforeunload", disconnect);

      if (isMobile) {
        // Add a listener to disconnect from the room when a mobile user closes their browser
        window.addEventListener("pagehide", disconnect);
      }
    }, function (error) {
      onError(error);
      setIsConnecting(false);
    });
  }, [localTracks, onError]);
  return {
    room: room,
    isConnecting: isConnecting,
    connect: connect
  };
}