import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useState } from "react";
import { useVideoContext } from "@src/context";
export default function useLocalVideoToggle() {
  var _useVideoContext = useVideoContext(),
      room = _useVideoContext.room,
      localTracks = _useVideoContext.localTracks,
      getLocalVideoTrack = _useVideoContext.getLocalVideoTrack,
      removeLocalVideoTrack = _useVideoContext.removeLocalVideoTrack,
      onError = _useVideoContext.onError;

  var localParticipant = room === null || room === void 0 ? void 0 : room.localParticipant;
  var videoTrack = localTracks.find(function (track) {
    return !track.name.includes("screen") && track.kind === "video";
  });

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isPublishing = _useState2[0],
      setIspublishing = _useState2[1];

  var toggleVideoEnabled = useCallback(function () {
    if (!isPublishing) {
      if (videoTrack) {
        var localTrackPublication = localParticipant === null || localParticipant === void 0 ? void 0 : localParticipant.unpublishTrack(videoTrack); // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592

        localParticipant === null || localParticipant === void 0 ? void 0 : localParticipant.emit("trackUnpublished", localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setIspublishing(true);
        getLocalVideoTrack().then(function (track) {
          return localParticipant === null || localParticipant === void 0 ? void 0 : localParticipant.publishTrack(track, {
            priority: "low"
          });
        }).catch(onError).finally(function () {
          setIspublishing(false);
        });
      }
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, onError, removeLocalVideoTrack]);
  return [!!videoTrack, toggleVideoEnabled];
}