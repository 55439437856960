import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { measurementTypes, specialCharacter, USER_KEY } from "@src/constants";
import moment, { duration } from "moment";
import { GraphTypes, TimeFilterValue // TMeasurementType,
} from "@src/globalTypes";
import { PATIENTS_URL } from "@src/app/routes";
export var setItem = function setItem(key, value) {
  localStorage.setItem(key, value);
};
export var getItem = function getItem(key) {
  var item = localStorage.getItem(key);

  if (item) {
    return JSON.parse(item);
  }

  return null;
};
export var removeItem = function removeItem(key) {
  localStorage.removeItem(key);
};
export var clearStorage = function clearStorage() {
  localStorage.clear();
};
export var isActiveRoute = function isActiveRoute(pathname, url) {
  return pathname === url;
};
export var getUserFromLocalStorage = function getUserFromLocalStorage() {
  return getItem(USER_KEY);
};
export var redirectToPatientListing = function redirectToPatientListing(history) {
  history.push(PATIENTS_URL);
};
export var validatePassword = function validatePassword(password) {
  var casePassed = {
    lengthTest: false,
    haveUpperCaseTest: false,
    haveLowerCaseTest: false,
    haveSpecialCharactersTest: false
  };

  if (password.length > 7) {
    casePassed.lengthTest = true;
  }

  if (/[A-Z]/.test(password)) {
    casePassed.haveUpperCaseTest = true;
  }

  if (/[a-z]/.test(password)) {
    casePassed.haveLowerCaseTest = true;
  }

  casePassed.haveSpecialCharactersTest = haveSpecialCharacters(password);
  return casePassed;
};
export var haveSpecialCharacters = function haveSpecialCharacters(string) {
  if (specialCharacter.test(string)) {
    return true;
  }

  return false;
};
export var formErrorMessageHandler = function formErrorMessageHandler(passedCases) {
  if (!passedCases.lengthTest) {
    return "Password must be six or more characters";
  }

  if (!passedCases.haveUpperCaseTest) {
    return "Password must contain a uppercase and a lowercase character";
  }

  if (!passedCases.haveLowerCaseTest) {
    return "Password must contain a uppercase and a lowercase character";
  }

  if (!passedCases.haveSpecialCharactersTest) {
    return "Password must contain a special character";
  }

  return false;
};
export var parsedError = function parsedError(error) {
  if (error === null) {
    return error;
  }

  if (error !== null && error !== void 0 && error.response) {
    var data = error.response.data;
    return data.errors && data.errors.length > 0 ? data.errors[0] : data.message || data.statusText || error.message;
  }

  if (error !== null && error !== void 0 && error.request) {
    return error.message;
  }

  return error;
};
export var parseAge = function parseAge(dob) {
  if (!dob) return undefined;
  var timeStampInSeconds = convertTimeStampIntoMiliSeconds(dob); // const dobFormated = new Date(timeStampInSeconds);
  // console.log(dobFormated);

  var now = Date.now();
  var age = duration(now - timeStampInSeconds).years();
  return age;
};
export var addTime = function addTime(timeStamp, value, format) {
  var result = Number(moment(timeStamp).add(value, format).format("x"));
  return result;
};
export var subtractTime = function subtractTime(timeStamp, value, format) {
  var result = Number(moment(timeStamp).subtract(value, format).format("x"));
  return result;
};
export var isGlucoseGraph = function isGlucoseGraph(key) {
  return key === GraphTypes.glucose;
};
export var isWeightGraph = function isWeightGraph(key) {
  return key === GraphTypes.weight;
};
export var isBloodPressureGraph = function isBloodPressureGraph(key) {
  return key === GraphTypes.bloodPressure;
};
export var convertTimeStampIntoMiliSeconds = function convertTimeStampIntoMiliSeconds(timeStamp) {
  return timeStamp * 1000;
};
export var parseDate = function parseDate(timeStamp) {
  var timeStampInSeconds = convertTimeStampIntoMiliSeconds(timeStamp);
  return new Date(timeStampInSeconds);
};
export var getTimeDifferenceFromNow = function getTimeDifferenceFromNow(timeStamp, differenceIn) {
  var currentDate = new Date();
  var itemDate = parseDate(timeStamp);
  var diff = moment(currentDate).diff(itemDate, differenceIn);
  return diff;
};
export var handleNotification = function handleNotification() {
  var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "title";
  var type = arguments.length > 1 ? arguments[1] : undefined;
  var timeout = arguments.length > 2 ? arguments[2] : undefined;

  for (var _len = arguments.length, rest = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
    rest[_key - 3] = arguments[_key];
  }

  return [{
    title: title
  }, {
    type: type,
    timeout: timeout || 5000
  }, _objectSpread({}, rest)];
};
export function a11yProps(index) {
  return {
    id: "simple-tab-".concat(index),
    "aria-controls": "simple-tabpanel-".concat(index)
  };
}
export var validateAddPlanForm = function validateAddPlanForm(values) {
  var obj = {};
  var haveError = false;
  Object.keys(values).map(function (key) {
    if (values[key].length) {
      obj[key] = "";
    } else {
      haveError = true;
      obj[key] = "This field is required";
    }
  });
  return haveError ? obj : haveError;
};
export var isThreeDayData = function isThreeDayData(key) {
  return key === TimeFilterValue[0];
};
export var isTwoWeeksData = function isTwoWeeksData(key) {
  return key === TimeFilterValue[1];
};
export var isMonthData = function isMonthData(key) {
  return key === TimeFilterValue[2];
};
export var isSixMonthsData = function isSixMonthsData(key) {
  return key === TimeFilterValue[3];
};
export var getUnit = function getUnit(measurementType, value) {
  var _measurementTypes$mea;

  return (_measurementTypes$mea = measurementTypes[measurementType]) === null || _measurementTypes$mea === void 0 ? void 0 : _measurementTypes$mea.values[value];
};
export var getTimeZone = function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
export var isMobile = function () {
  if (typeof navigator === "undefined" || typeof navigator.userAgent !== "string") {
    return false;
  }

  return /Mobile/.test(navigator.userAgent);
}();