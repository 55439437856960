// This function is used to provide error messages to the user that are
// different than the error messages provided by the SDK.
export default function enhanceMessage() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var code = arguments.length > 1 ? arguments[1] : undefined;

  switch (code) {
    case 20101:
      // Invalid token error
      return "".concat(message, ". Please make sure you are using the correct credentials.");

    default:
      return message;
  }
}