import { useContext } from "react";
import { VideoContext } from "./index";
export default function useVideoContext() {
  var context = useContext(VideoContext);

  if (!context) {
    throw new Error("useVideoContext must be used within a VideoProvider");
  }

  return context;
}