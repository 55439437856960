import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { useChatClientContext } from "@src/context";

var useChatParticipantUpdated = function useChatParticipantUpdated() {
  var _useChatClientContext = useChatClientContext(),
      chatClient = _useChatClientContext.chatClient;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      chatParticipantUpdated = _useState2[0],
      setChatParticipantUpdated = _useState2[1];

  useEffect(function () {
    var chatParticipantUpdatedHandler = function chatParticipantUpdatedHandler(state) {
      setChatParticipantUpdated(state);
    };

    if (chatClient) {
      chatClient.on("participantUpdated", chatParticipantUpdatedHandler);
    }

    return function () {
      chatClient === null || chatClient === void 0 ? void 0 : chatClient.off("participantUpdated", chatParticipantUpdatedHandler);
    };
  }, [chatClient]);
  return {
    chatParticipantUpdated: chatParticipantUpdated
  };
};

export default useChatParticipantUpdated;