import { useContext } from "react";
import { PatientInformationHistoryContext } from "./index";
export default function usePatientInformationHistoryContext() {
  var context = useContext(PatientInformationHistoryContext);

  if (!context) {
    throw new Error("usePatientInformationHistoryContext must be used within a PatientInformationHistoryProvider");
  }

  return context;
}