import { useContext } from "react";
import { NotificationsContext } from "./index";
export default function useNotificationsContext() {
  var context = useContext(NotificationsContext);

  if (!context) {
    throw new Error("useNotificationsContext must be used within a NotificationsProvider");
  }

  return context;
}