import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
export var parseAuthor = function parseAuthor() {
  var author = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";

  var _author$split = author.split("-"),
      _author$split2 = _slicedToArray(_author$split, 2),
      envoirment = _author$split2[0],
      id = _author$split2[1];

  return {
    envoirment: envoirment,
    id: Number(id)
  };
};
export var isConnectionLoadingState = function isConnectionLoadingState(chatConnectionState) {
  return chatConnectionState === "connecting" || chatConnectionState === "disconnected" || chatConnectionState === "disconnecting";
};