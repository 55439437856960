import variables from "@src/theme/_variables.scss";
import moment from "moment";
export var getAnnotations = function getAnnotations(metabolicSubCategory) {
  // console.log("metabolicSubCategory", metabolicSubCategory);
  var _ref = metabolicSubCategory || {},
      gray_min = _ref.gray_min,
      gray_max = _ref.gray_max,
      green_min = _ref.green_min,
      green_max = _ref.green_max,
      yellow_min = _ref.yellow_min,
      yellow_max = _ref.yellow_max,
      red_max = _ref.red_max,
      red_min = _ref.red_min;

  if (Number(green_max) > Number(red_max)) {
    if (Number(yellow_max) < Number(green_max)) {
      return {
        green: green_max ? {
          drawTime: "beforeDraw",
          type: "box",
          // yMin: gray_max || green_min,
          // yMax: green_max,
          yScaleID: "yAxes",
          borderColor: variables.chartGreen,
          borderWidth: 0,
          backgroundColor: variables.chartGreen
        } : undefined,
        yellow: yellow_max ? {
          drawTime: "beforeDraw",
          type: "box",
          yMin: red_max ? red_max || yellow_min : undefined,
          yMax: yellow_max,
          yScaleID: "yAxes",
          borderColor: variables.chartYellow,
          borderWidth: 0,
          backgroundColor: variables.chartYellow
        } : undefined,
        red: red_max ? {
          drawTime: "beforeDraw",
          type: "box",
          yMin: red_min,
          yMax: red_max,
          yScaleID: "yAxes",
          borderColor: variables.chartRed,
          borderWidth: 0,
          backgroundColor: variables.chartRed
        } : undefined
      };
    }

    return {
      yellow: yellow_max ? {
        drawTime: "beforeDraw",
        type: "box",
        // yMin: red_max ? red_max || yellow_min : undefined,
        // yMax: yellow_max,
        yScaleID: "yAxes",
        borderColor: variables.chartYellow,
        borderWidth: 0,
        backgroundColor: variables.chartYellow
      } : undefined,
      green: green_max ? {
        drawTime: "beforeDraw",
        type: "box",
        yMin: green_min,
        yMax: green_max,
        yScaleID: "yAxes",
        borderColor: variables.chartGreen,
        borderWidth: 0,
        backgroundColor: variables.chartGreen
      } : undefined
    };
  }

  var annotations = {
    red: red_max ? {
      drawTime: "beforeDraw",
      type: "box",
      yMin: gray_max ? yellow_max : undefined,
      // yMax: positiveReadingMin,
      yScaleID: "yAxes",
      borderColor: variables.chartRed,
      borderWidth: 0,
      backgroundColor: variables.chartRed
    } : undefined,
    yellow: yellow_max ? {
      drawTime: "beforeDraw",
      type: "box",
      yMin: red_max ? green_max || yellow_min : undefined,
      yMax: red_max ? yellow_max : undefined,
      yScaleID: "yAxes",
      borderColor: variables.chartYellow,
      borderWidth: 0,
      backgroundColor: variables.chartYellow
    } : undefined,
    green: green_max ? {
      drawTime: "beforeDraw",
      type: "box",
      yMin: gray_max || green_min,
      yMax: green_max,
      yScaleID: "yAxes",
      borderColor: variables.chartGreen,
      borderWidth: 0,
      backgroundColor: variables.chartGreen
    } : undefined,
    gray: gray_max ? {
      drawTime: "beforeDraw",
      type: "box",
      yMin: gray_min || undefined,
      yMax: gray_max,
      yScaleID: "yAxes",
      borderColor: variables.chartGray,
      borderWidth: 0,
      backgroundColor: variables.chartGray
    } : undefined
  };
  return annotations;
};
export var getOrCreateTooltip = function getOrCreateTooltip(chart) {
  var tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.minWidth = "100px";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.classList.add("tooltip-v1");
    var table = document.createElement("table");
    table.style.margin = "0px";
    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};
export var externalTooltipHandler = function externalTooltipHandler(context, unit, isGlucoseGraph) {
  var _tooltip$dataPoints, _tooltip$dataPoints$, _toolTipData$reading_, _toolTipData$reading_2;

  // Tooltip Element
  var chart = context.chart,
      tooltip = context.tooltip;
  if (!(tooltip !== null && tooltip !== void 0 && (_tooltip$dataPoints = tooltip.dataPoints) !== null && _tooltip$dataPoints !== void 0 && _tooltip$dataPoints.length)) return;
  var toolTipData = tooltip === null || tooltip === void 0 ? void 0 : (_tooltip$dataPoints$ = tooltip.dataPoints[0]) === null || _tooltip$dataPoints$ === void 0 ? void 0 : _tooltip$dataPoints$.raw;
  var tooltipEl = getOrCreateTooltip(chart); // console.log("unit",unit);

  //   console.log(tooltipEl);
  // console.log(toolTipData);
  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  } // Set Text
  //   if (tooltip.body) {


  var tableHead = document.createElement("thead");
  var trHead = document.createElement("tr");
  trHead.style.borderWidth = "0px";
  var readingElement = document.createElement("span");
  readingElement.style.color = variables.turquoiseBlue;
  var th = document.createElement("th");
  th.style.borderWidth = "0px";
  th.style.display = "flex";
  th.style.alignItems = "center";
  var reading = document.createTextNode(toolTipData === null || toolTipData === void 0 ? void 0 : toolTipData.reading.toFixed(2));

  if (isGlucoseGraph && unit === "mg/dl") {
    reading = document.createTextNode(toolTipData === null || toolTipData === void 0 ? void 0 : toolTipData.reading.toFixed(0));
  }

  var readingUnitElement = document.createElement("h3");
  readingUnitElement.style.fontSize = "12px";
  readingUnitElement.style.marginLeft = "5px";
  var readingUnitText = document.createTextNode(unit);

  if (unit) {
    readingUnitElement.appendChild(readingUnitText);
  }

  readingElement.appendChild(reading);
  th.appendChild(readingElement);
  th.appendChild(readingUnitElement);
  trHead.appendChild(th);
  tableHead.appendChild(trHead);
  var tableBody = document.createElement("tbody");
  var readingTimeEl = document.createElement("span");
  var readingDateEl = document.createElement("span");
  var readingCommentIcon = document.createElement("span");
  var readingCommentsEl = document.createElement("span");
  readingTimeEl.className = "reading-time-text";
  readingDateEl.className = "reading-date-text";
  readingCommentsEl.className = "reading-comments-text";
  readingCommentIcon.className = "icon-chat"; // span.style.background = colors.backgroundColor;
  //   span.style.borderColor = colors.borderColor;
  //   span.style.borderWidth = "2px";
  //   span.style.marginRight = "10px";
  //   span.style.height = "10px";
  //   span.style.width = "10px";
  //   span.style.display = "inline-block";

  var trBody = document.createElement("tr");
  trBody.style.backgroundColor = "inherit";
  trBody.style.borderWidth = "0px";
  var td = document.createElement("td");
  td.style.borderWidth = "0px";

  if (toolTipData !== null && toolTipData !== void 0 && (_toolTipData$reading_ = toolTipData.reading_time) !== null && _toolTipData$reading_ !== void 0 && _toolTipData$reading_.type) {
    var readingTime = "".concat(toolTipData.reading_time.type.split(" ")[0], " ").concat(toolTipData.reading_type.type);
    readingTimeEl.textContent = readingTime;
  }

  var readingDate = moment(toolTipData.timestamp).format("D MMM, YYYY, h:mm A"); // console.log(readingDate);

  readingDateEl.textContent = readingDate;
  readingCommentsEl.textContent = (toolTipData === null || toolTipData === void 0 ? void 0 : toolTipData.comments) || "";

  if (toolTipData !== null && toolTipData !== void 0 && (_toolTipData$reading_2 = toolTipData.reading_time) !== null && _toolTipData$reading_2 !== void 0 && _toolTipData$reading_2.type) {
    td.appendChild(readingTimeEl);
  }

  td.appendChild(readingDateEl);

  if (toolTipData !== null && toolTipData !== void 0 && toolTipData.comments) {
    readingCommentsEl.prepend(readingCommentIcon);
    td.appendChild(readingCommentsEl);
  } // td.appendChild(readingTime);


  trBody.appendChild(td);
  tableBody.appendChild(trBody); // });

  var tableRoot = tooltipEl.querySelector("table"); // Remove old children

  while (tableRoot.firstChild) {
    tableRoot.firstChild.remove();
  } // Add new children


  tableRoot.appendChild(tableHead);
  tableRoot.appendChild(tableBody); //   }

  var _chart$canvas = chart.canvas,
      positionX = _chart$canvas.offsetLeft,
      positionY = _chart$canvas.offsetTop; //   console.log(positionY + tooltip.caretY - 62);
  // console.log("T", chart);
  // console.log("K", positionX);
  // console.log(tooltip.caretX);
  // Display, position, and set styles for font

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = "".concat(positionX + tooltip.caretX - 50, "px");
  tooltipEl.style.top = "".concat(positionY + tooltip.caretY - 60, "px");
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = "".concat(tooltip.options.padding, "px ").concat(tooltip.options.padding, "px");
};
export var multiLineTooltipHandler = function multiLineTooltipHandler(context, unit) {
  var _tooltip$dataPoints2, _toolTipData$dataset;

  // Tooltip Element
  // console.log("unit", unit);
  var chart = context.chart,
      tooltip = context.tooltip;
  if (!(tooltip !== null && tooltip !== void 0 && (_tooltip$dataPoints2 = tooltip.dataPoints) !== null && _tooltip$dataPoints2 !== void 0 && _tooltip$dataPoints2.length)) return;
  var toolTipData = tooltip === null || tooltip === void 0 ? void 0 : tooltip.dataPoints[0];
  var tooltipEl = getOrCreateTooltip(chart); // console.log("KKK", context);
  // console.log("JJJ TP", tooltip);
  //   console.log(tooltipEl);
  // console.log(toolTipData);
  // Hide if no tooltip

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  } // Set Text
  //   if (tooltip.body) {


  var tableHead = document.createElement("thead");
  var trHead = document.createElement("tr");
  var otherTrHead = document.createElement("tr");
  trHead.style.borderWidth = "0px";
  otherTrHead.style.borderWidth = "0px";
  var readingElement = document.createElement("span");
  readingElement.style.color = variables.turquoiseBlue;
  var otherReadingElement = document.createElement("span");
  otherReadingElement.style.color = variables.turquoiseBlue;
  var th = document.createElement("th");
  th.style.borderWidth = "0px";
  th.style.display = "flex";
  th.style.alignItems = "center";
  var otherTh = document.createElement("th");
  otherTh.style.borderWidth = "0px";
  otherTh.style.display = "flex";
  otherTh.style.alignItems = "center";
  var otherLineIndex;

  if (toolTipData.datasetIndex === 0) {
    otherLineIndex = 1;
  } else {
    otherLineIndex = 0;
  } // additional data


  var otherLineData = chart._sortedMetasets[otherLineIndex]._dataset; // console.log(otherLineData);

  var otherReading = document.createTextNode(otherLineData === null || otherLineData === void 0 ? void 0 : otherLineData.data[toolTipData.dataIndex].toFixed(2));
  var otherReadingUnitElement = document.createElement("h3");
  otherReadingUnitElement.style.fontSize = "12px";
  otherReadingUnitElement.style.marginLeft = "5px";
  var otherReadingUnitText = document.createTextNode(unit);
  otherReadingUnitElement.appendChild(otherReadingUnitText);
  otherReadingElement.appendChild(otherReading);
  otherTh.appendChild(otherReadingElement);
  otherTh.appendChild(otherReadingUnitElement);
  var otherDatasetLabelEl = document.createElement("span");
  otherDatasetLabelEl.className = "dataset-label-text";
  otherDatasetLabelEl.textContent = otherLineData === null || otherLineData === void 0 ? void 0 : otherLineData.label;
  otherTrHead.appendChild(otherTh);
  otherTrHead.appendChild(otherDatasetLabelEl);
  var reading = document.createTextNode(toolTipData === null || toolTipData === void 0 ? void 0 : toolTipData.raw.toFixed(2));
  var readingUnitElement = document.createElement("h3");
  readingUnitElement.style.fontSize = "12px";
  readingUnitElement.style.marginLeft = "5px";
  var readingUnitText = document.createTextNode(unit);
  readingUnitElement.appendChild(readingUnitText);
  readingElement.appendChild(reading);
  th.appendChild(readingElement);
  th.appendChild(readingUnitElement);
  var datasetLabelEl = document.createElement("span");
  datasetLabelEl.className = "dataset-label-text";
  datasetLabelEl.textContent = toolTipData === null || toolTipData === void 0 ? void 0 : (_toolTipData$dataset = toolTipData.dataset) === null || _toolTipData$dataset === void 0 ? void 0 : _toolTipData$dataset.label;
  trHead.appendChild(th);
  trHead.appendChild(datasetLabelEl);

  if (toolTipData.datasetIndex === 1) {
    tableHead.appendChild(otherTrHead);
  }

  tableHead.appendChild(trHead);

  if (toolTipData.datasetIndex === 0) {
    tableHead.appendChild(otherTrHead);
  }

  var tableBody = document.createElement("tbody");
  var readingDateEl = document.createElement("span");
  readingDateEl.className = "reading-date-text"; // span.style.background = colors.backgroundColor;
  //   span.style.borderColor = colors.borderColor;
  //   span.style.borderWidth = "2px";
  //   span.style.marginRight = "10px";
  //   span.style.height = "10px";
  //   span.style.width = "10px";
  //   span.style.display = "inline-block";

  var trBody = document.createElement("tr");
  trBody.style.backgroundColor = "inherit";
  trBody.style.borderWidth = "0px";
  var td = document.createElement("td");
  td.style.borderWidth = "0px"; // const readingTime = `${toolTipData.dataset.label.split(" ")[0]} ${
  //   toolTipData.reading_type.type
  // }`;

  var readingDate = moment(Number(tooltip.title[0])).format("D MMM, YYYY, h:mm A"); // console.log(readingDate);

  readingDateEl.textContent = readingDate; // console.log(readingTimeEl);

  td.appendChild(readingDateEl); // td.appendChild(readingTime);

  trBody.appendChild(td);
  tableBody.appendChild(trBody); // });

  var tableRoot = tooltipEl.querySelector("table"); // Remove old children

  while (tableRoot.firstChild) {
    tableRoot.firstChild.remove();
  } // Add new children


  tableRoot.appendChild(tableHead);
  tableRoot.appendChild(tableBody); //   }

  var _chart$canvas2 = chart.canvas,
      positionX = _chart$canvas2.offsetLeft,
      positionY = _chart$canvas2.offsetTop; //   console.log(positionY + tooltip.caretY - 62);
  // console.log("T", chart);
  // console.log("K", positionX);
  // console.log(tooltip.caretX);
  // Display, position, and set styles for font

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = "".concat(positionX + tooltip.caretX - 50, "px");
  tooltipEl.style.top = "".concat(positionY + tooltip.caretY - 97, "px");
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = "".concat(tooltip.options.padding, "px ").concat(tooltip.options.padding, "px");
};

var getOrCreateLegendList = function getOrCreateLegendList(chart, id) {
  var legendContainer = document.getElementById(id);
  var listContainer = legendContainer.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "flex";
    listContainer.style.flexDirection = "row";
    listContainer.style.margin = "10px 0 0 0";
    listContainer.style.padding = "0px";
    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export var getOrCreateModal = function getOrCreateModal(chart) {
  var tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.minWidth = "100px";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.classList.add("tooltip-modal-v1");
    var table = document.createElement("table");
    table.style.margin = "0px";
    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};
export var externalMobileTooltipHandler = function externalMobileTooltipHandler(context, unit, isGlucoseGraph) {
  var _tooltip$dataPoints3, _tooltip$dataPoints$2, _toolTipData$reading_3, _toolTipData$reading_4;

  // Tooltip Element
  var chart = context.chart,
      tooltip = context.tooltip;
  if (!(tooltip !== null && tooltip !== void 0 && (_tooltip$dataPoints3 = tooltip.dataPoints) !== null && _tooltip$dataPoints3 !== void 0 && _tooltip$dataPoints3.length)) return;
  var toolTipData = tooltip === null || tooltip === void 0 ? void 0 : (_tooltip$dataPoints$2 = tooltip.dataPoints[0]) === null || _tooltip$dataPoints$2 === void 0 ? void 0 : _tooltip$dataPoints$2.raw;
  var tooltipEl = getOrCreateModal(chart); // console.log("unit",unit);

  //   console.log(tooltipEl);
  // console.log(toolTipData);
  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  } // Set Text
  //   if (tooltip.body) {


  var tableHead = document.createElement("thead");
  var trHead = document.createElement("tr");
  trHead.style.borderWidth = "0px";
  var readingElement = document.createElement("span");
  readingElement.style.color = variables.turquoiseBlue;
  var th = document.createElement("th");
  th.style.borderWidth = "0px";
  th.style.display = "flex";
  th.style.alignItems = "center";
  var reading = document.createTextNode(toolTipData === null || toolTipData === void 0 ? void 0 : toolTipData.reading.toFixed(2));

  if (isGlucoseGraph && unit === "mg/dl") {
    reading = document.createTextNode(toolTipData === null || toolTipData === void 0 ? void 0 : toolTipData.reading.toFixed(0));
  }

  var readingUnitElement = document.createElement("h3");
  readingUnitElement.style.fontSize = "12px";
  readingUnitElement.style.marginLeft = "5px";
  var readingUnitText = document.createTextNode(unit);

  if (unit) {
    readingUnitElement.appendChild(readingUnitText);
  }

  readingElement.appendChild(reading);
  th.appendChild(readingElement);
  th.appendChild(readingUnitElement);
  trHead.appendChild(th);
  tableHead.appendChild(trHead);
  var tableBody = document.createElement("tbody");
  var readingTimeEl = document.createElement("span");
  var readingDateEl = document.createElement("span");
  var readingCommentIcon = document.createElement("span");
  var readingCommentsEl = document.createElement("span");
  readingTimeEl.className = "reading-time-text";
  readingDateEl.className = "reading-date-text";
  readingCommentsEl.className = "reading-comments-text";
  readingCommentIcon.className = "icon-chat"; // span.style.background = colors.backgroundColor;
  //   span.style.borderColor = colors.borderColor;
  //   span.style.borderWidth = "2px";
  //   span.style.marginRight = "10px";
  //   span.style.height = "10px";
  //   span.style.width = "10px";
  //   span.style.display = "inline-block";

  var trBody = document.createElement("tr");
  trBody.style.backgroundColor = "inherit";
  trBody.style.borderWidth = "0px";
  var td = document.createElement("td");
  td.style.borderWidth = "0px";

  if (toolTipData !== null && toolTipData !== void 0 && (_toolTipData$reading_3 = toolTipData.reading_time) !== null && _toolTipData$reading_3 !== void 0 && _toolTipData$reading_3.type) {
    var readingTime = "".concat(toolTipData.reading_time.type.split(" ")[0], " ").concat(toolTipData.reading_type.type);
    readingTimeEl.textContent = readingTime;
  }

  var readingDate = moment(toolTipData.timestamp).format("D MMM, YYYY, h:mm A"); // console.log(readingDate);

  readingDateEl.textContent = readingDate;
  readingCommentsEl.textContent = (toolTipData === null || toolTipData === void 0 ? void 0 : toolTipData.comments) || "";

  if (toolTipData !== null && toolTipData !== void 0 && (_toolTipData$reading_4 = toolTipData.reading_time) !== null && _toolTipData$reading_4 !== void 0 && _toolTipData$reading_4.type) {
    td.appendChild(readingTimeEl);
  }

  td.appendChild(readingDateEl);

  if (toolTipData !== null && toolTipData !== void 0 && toolTipData.comments) {
    readingCommentsEl.prepend(readingCommentIcon);
    td.appendChild(readingCommentsEl);
  } // td.appendChild(readingTime);


  trBody.appendChild(td);
  tableBody.appendChild(trBody); // });

  var tableRoot = tooltipEl.querySelector("table"); // Remove old children

  while (tableRoot.firstChild) {
    tableRoot.firstChild.remove();
  } // Add new children


  tableRoot.appendChild(tableHead);
  tableRoot.appendChild(tableBody); //   }

  var _chart$canvas3 = chart.canvas,
      positionX = _chart$canvas3.offsetLeft,
      positionY = _chart$canvas3.offsetTop; //   console.log(positionY + tooltip.caretY - 62);
  // console.log("T", chart);
  // console.log("K", positionX);
  // console.log(tooltip.caretX);
  // Display, position, and set styles for font

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = "".concat(positionX + tooltip.caretX - 50, "px");
  tooltipEl.style.top = "".concat(positionY + tooltip.caretY - 60, "px");
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = "".concat(tooltip.options.padding, "px ").concat(tooltip.options.padding, "px");
};
export var htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate: function afterUpdate(chart, args, options) {
    var ul = getOrCreateLegendList(chart, options.containerID); // Remove old legend items

    while (ul.firstChild) {
      ul.firstChild.remove();
    } // Reuse the built-in legendItems generator


    var items = chart.options.plugins.legend.labels.generateLabels(chart);
    items.forEach(function (item) {
      var li = document.createElement("li");
      li.style.alignItems = "center";
      li.style.cursor = "pointer";
      li.style.display = "flex";
      li.style.flexDirection = "row";
      li.style.marginLeft = "10px"; // li.onclick = () => {
      //   const { type } = chart.config;
      //   if (type === "pie" || type === "doughnut") {
      //     // Pie and doughnut charts only have a single dataset and visibility is per item
      //     chart.toggleDataVisibility(item.index);
      //   } else {
      //     chart.setDatasetVisibility(
      //       item.datasetIndex,
      //       !chart.isDatasetVisible(item.datasetIndex)
      //     );
      //   }
      //   chart.update();
      // };
      // Color box

      var boxSpan = document.createElement("span");
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = "".concat(item.lineWidth, "px");
      boxSpan.style.display = "inline-block";
      boxSpan.style.height = "15px";
      boxSpan.style.marginRight = "10px";
      boxSpan.style.width = "15px"; // Text

      var textContainer = document.createElement("p");
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = "0px";
      textContainer.style.padding = "0px";
      textContainer.style.textDecoration = item.hidden ? "line-through" : "";
      var text = document.createTextNode(item.text);
      textContainer.appendChild(text);
      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  }
};