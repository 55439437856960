import { useCallback } from "react";
import { useVideoContext } from "@src/context";
import { useIsTrackEnabled } from "..";
export default function useLocalAudioToggle() {
  var _useVideoContext = useVideoContext(),
      localTracks = _useVideoContext.localTracks;

  var audioTrack = localTracks.find(function (track) {
    return track.kind === "audio";
  });
  var isEnabled = useIsTrackEnabled(audioTrack);
  var toggleAudioEnabled = useCallback(function () {
    if (audioTrack) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    }
  }, [audioTrack]);
  return [isEnabled, toggleAudioEnabled];
}