import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";

var useInput = function useInput(initialValue, setUpdated) {
  var _useState = useState(initialValue),
      _useState2 = _slicedToArray(_useState, 2),
      text = _useState2[0],
      setText = _useState2[1];

  var reset = function reset() {
    setText(initialValue);
  };

  var onChange = function onChange(e) {
    if (setUpdated) {
      setUpdated(true);
    }

    setText(e.target.value);
  };

  return [text, onChange, reset, setText];
};

export default useInput;