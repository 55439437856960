import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";

var useInputNumber = function useInputNumber(initialValue, setUpdated) {
  var _useState = useState(initialValue),
      _useState2 = _slicedToArray(_useState, 2),
      number = _useState2[0],
      setNumber = _useState2[1];

  var reset = function reset() {
    setNumber(initialValue);
  };

  var onChange = function onChange(e) {
    var _e$target = e.target,
        value = _e$target.value,
        min = _e$target.min,
        max = _e$target.max;

    if (setUpdated) {
      setUpdated(true);
    }

    var re = new RegExp(/^\d*\.?\d*$/); // const checkDecimalPlaces = new RegExp(/^\d+(\.\d{0,2})?$/);
    // if (value.includes(".") && !checkDecimalPlaces.test(value)) return;

    if (value.includes(".")) return; // if value is not blank, then test the regex

    if (value !== "" && value < min) return;
    if (Number(e.target.value) > Number(max)) return;

    if (value === "" || re.test(value)) {
      setNumber(e.target.value);
    }
  };

  return [number, onChange, reset, setNumber];
};

export default useInputNumber;