// exports
var API_URL = process.env.API_URL;
export { API_URL };
var ENVIRONMENT = process.env.ENVIRONMENT;
export { ENVIRONMENT };
var TWILIO_SERVICE_ENV = process.env.TWILIO_SERVICE_ENV;
export { TWILIO_SERVICE_ENV };
var BMX_PLUS_API_URL = process.env.BMX_PLUS_API_URL;
export { BMX_PLUS_API_URL };
export var ACCESS_TOKEN = "accessToken";
export var USER_KEY = "user";
export var DATA_NOT_FOUND = "N/A";
export var DEFAULT_VIDEO_CONSTRAINTS = {
  width: 1280,
  height: 720,
  frameRate: 24
};
export var profileMoreDropdownList = [{
  id: 1,
  name: "My Profile",
  type: "my-profile"
}, // {
//   id: 2,
//   name: "Change Password",
//   type: "change-password",
// },
{
  id: 2,
  name: "Logout",
  type: "logout"
}];
export var specializationSelectOptions = [{
  id: 1,
  name: "Option-1",
  value: "option-1"
}, {
  id: 2,
  name: "Option-2",
  value: "option-2"
}, {
  id: 3,
  name: "Option-3",
  value: "option-3"
}, {
  id: 4,
  name: "Option-4",
  value: "option-4"
}];
export var specialCharacter = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
export var measurementTypes = {
  metric: {
    id: 1,
    values: {
      glucose: "mmol/L",
      weight: "Kg",
      waist: "Cm",
      height: "Cm",
      bloodPressure: "mmHg"
    }
  },
  imperial: {
    id: 2,
    values: {
      glucose: "mg/dl",
      weight: "Lbs",
      waist: "Inches",
      height: "Ft/in",
      bloodPressure: "mmHg"
    }
  }
}; // TWILIO ALLOWED FILE TYPES:
// export const CHAT_ALLOWED_FILE_TYPES =
//   "audio/*, image/*, text/*, video/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document .xslx, .ppt, .pdf, .key, .svg, .csv";

export var CHAT_ALLOWED_FILE_TYPES = "image/*,  .pdf";
export var CHAT_MESSAGES_COUNT = 20;
export var TWILIO_ROOM_COMPLETED_ERROR_CODE = 53118;
export var timeFilterButtonArr = ["7 Days", "1 month", "3 month", "6 months"];
export var metabolicTimeFilterButtonArr = ["3 Days", "2 weeks", "1 month", "6 months"];
export var glucoseTimeFilterButtonArr = ["24 hours", "7 Days", "1 month", "3 months"];