import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from "react";
// The 'switchedOff' event is emitted when there is not enough bandwidth to support
// a track. See: https://www.twilio.com/docs/video/tutorials/using-bandwidth-profile-api#understanding-track-switch-offs
export default function useIsTrackSwitchedOff(track) {
  var _useState = useState(track && track.isSwitchedOff),
      _useState2 = _slicedToArray(_useState, 2),
      isSwitchedOff = _useState2[0],
      setIsSwitchedOff = _useState2[1];

  useEffect(function () {
    // Reset the value if the 'track' variable changes
    setIsSwitchedOff(track && track.isSwitchedOff);

    if (track) {
      var handleSwitchedOff = function handleSwitchedOff() {
        return setIsSwitchedOff(true);
      };

      var handleSwitchedOn = function handleSwitchedOn() {
        return setIsSwitchedOff(false);
      };

      track.on("switchedOff", handleSwitchedOff);
      track.on("switchedOn", handleSwitchedOn);
      return function () {
        track.off("switchedOff", handleSwitchedOff);
        track.off("switchedOn", handleSwitchedOn);
      };
    }
  }, [track]);
  return !!isSwitchedOff;
}