import { useContext } from "react";
import { AccountAndPaymentsContext } from "./index";
export default function useAccountAndPaymentsContext() {
  var context = useContext(AccountAndPaymentsContext);

  if (!context) {
    throw new Error("useAccountAndPaymentsContext must be used within a AccountAndPaymentsProvider");
  }

  return context;
}