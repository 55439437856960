import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from "react";
export default function useIsTrackEnabled(track) {
  var _useState = useState(track ? track.isEnabled : false),
      _useState2 = _slicedToArray(_useState, 2),
      isEnabled = _useState2[0],
      setIsEnabled = _useState2[1];

  useEffect(function () {
    setIsEnabled(track ? track.isEnabled : false);

    if (track) {
      var setEnabled = function setEnabled() {
        return setIsEnabled(true);
      };

      var setDisabled = function setDisabled() {
        return setIsEnabled(false);
      };

      track.on("enabled", setEnabled);
      track.on("disabled", setDisabled);
      return function () {
        track.off("enabled", setEnabled);
        track.off("disabled", setDisabled);
      };
    }
  }, [track]);
  return isEnabled;
}