import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useState } from "react"; // ----------------------------------------------------------------------

function useBoolean(defaultValue) {
  var _useState = useState(!!defaultValue),
      _useState2 = _slicedToArray(_useState, 2),
      value = _useState2[0],
      setValue = _useState2[1];

  var onTrue = useCallback(function (value) {
    setValue((value === null || value === void 0 ? void 0 : value.identity) || true);
  }, []);
  var onFalse = useCallback(function () {
    setValue(false);
  }, []);
  var onToggle = useCallback(function () {
    setValue(function (prev) {
      return !prev;
    });
  }, []);
  return {
    value: value,
    onTrue: onTrue,
    onFalse: onFalse,
    onToggle: onToggle,
    setValue: setValue
  };
}

export default useBoolean;