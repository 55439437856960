import { useEffect } from "react";
export default function useHandleTrackPublicationFailed(room, onError) {
  useEffect(function () {
    if (room) {
      room.localParticipant.on("trackPublicationFailed", onError);
      return function () {
        room.localParticipant.off("trackPublicationFailed", onError);
      };
    }
  }, [room, onError]);
}