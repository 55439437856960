import { BMX_PLUS_URL, BUSINESS_DASHBOARD_URL, DASHBOARD_URL, INVITE_CLIENT_URL, // NOTIFICATIONS,
ACCOUNT_AND_PAYMENTS_URL, PATIENTS_URL } from "./paths";
export var navigations = [{
  name: "Dashboard",
  icon: {
    className: "iconic-btn chat-icon",
    iconName: "th-large",
    iconClassName: "attach-icon"
  },
  route: DASHBOARD_URL
}, {
  name: "My Clients",
  icon: {
    className: "iconic-btn chat-icon",
    iconName: "procedures",
    iconClassName: "attach-icon"
  },
  route: PATIENTS_URL
}, {
  name: "My Business",
  icon: {
    className: "iconic-btn chat-icon",
    iconName: "book-open",
    iconClassName: "attach-icon"
  },
  route: BUSINESS_DASHBOARD_URL
}, {
  name: "Knowledge Hub",
  icon: {
    className: "iconic-btn chat-icon",
    iconName: "book-medical",
    iconClassName: "attach-icon"
  },
  route: "https://www.biomarkx.com/resource/knowledge-hub",
  isLink: true
}, {
  name: "Biomarkx Plus",
  icon: {
    className: "iconic-btn chat-icon",
    iconName: "sync",
    iconClassName: "attach-icon"
  },
  route: BMX_PLUS_URL
}, // {
//   name: "Calendar",
//   icon: {
//     className: "iconic-btn chat-icon",
//     iconName: "calendar",
//     iconClassName: "attach-icon",
//   },
//   route: UPCOMING_CALLS,
// },
// {
//   name: "Calendly",
//   icon: {
//     className: "iconic-btn chat-icon",
//     iconName: "calendar",
//     iconClassName: "attach-icon",
//   },
//   route: CAlENDLY_URL,
// },
// {
//   name: "Notifications",
//   icon: {
//     className: "iconic-btn chat-icon",
//     iconName: "bell",
//     iconClassName: "attach-icon",
//   },
//   route: NOTIFICATIONS,
// },
{
  name: "Invite new clients",
  icon: {
    className: "iconic-btn chat-icon",
    iconName: "user-plus",
    iconClassName: "attach-icon"
  },
  route: INVITE_CLIENT_URL
}, {
  name: "Account & Payments",
  icon: {
    className: "iconic-btn chat-icon",
    iconName: "money-check",
    iconClassName: "attach-icon"
  },
  route: ACCOUNT_AND_PAYMENTS_URL
}];