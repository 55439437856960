import { useContext } from "react";
import { ChatBotContext } from "./index";
export default function useChatBotContext() {
  var context = useContext(ChatBotContext);

  if (!context) {
    throw new Error("useChatBotContext must be used within a ChatBotProvider");
  }

  return context;
}