import { API_URL, BMX_PLUS_API_URL } from "../constants"; // KEYS

export var TOKEN_BASE = "Bearer";
export var AUTHORIZATION = "Authorization";
export var API_VERSION = 1;
export var BASE_URL = "".concat(API_URL, "api/v").concat(API_VERSION);
export var GET_META_API_URL = "".concat(BASE_URL, "/metadata");
export var LOGIN_API_URL = "".concat(BASE_URL, "/clinicalTeamLogin");
export var LOGOUT_API_URL = "".concat(BASE_URL, "/logout");
export var FORGOT_PASSWORD_API_URL = "".concat(BASE_URL, "/forgotPassword");
export var RESET_PASSWORD_API_URL = "".concat(BASE_URL, "/resetPassword");
export var PATIENT_LISTING = "".concat(BASE_URL, "/getAllPatients");
export var PROFILE_PIC_UPLOAD = "".concat(BASE_URL, "/storeUserProfilePic");
export var GET_PROFILE_URL = "".concat(BASE_URL, "/getClinicalTeamProfile");
export var UPDATE_PROFILE_URL = "".concat(BASE_URL, "/updateClinicalTeamProfile");
export var GET_PATIENT_INFORMATION_URL = "".concat(BASE_URL, "/getPatientProfile");
export var GET_PATIENT_PLAN_URL = "".concat(BASE_URL, "/getTreatmentPlan");
export var ADD_PATIENT_PLAN_URL = "".concat(BASE_URL, "/storeTreatmentPlan");
export var INVITE_PATIENT_URL = "".concat(BASE_URL, "/invitePatients");
export var ADD_CLINICAL_NOTE_URL = "".concat(BASE_URL, "/storeClinicalNote");
export var GET_GLUCOSE_TRENDS = "".concat(BASE_URL, "/glucoseTrends");
export var GET_WEIGHT_TRENDS = "".concat(BASE_URL, "/weightTrends");
export var GET_BLOOD_PRESSURE_TRENDS = "".concat(BASE_URL, "/bpTrends");
export var GET_METABOLIC_TRENDS = "".concat(BASE_URL, "/getPatientMetabolicReadings");
export var GET_ACTION_PLAN_LIST_URL = "".concat(BASE_URL, "/getTreatmentPlanHistory");
export var GET_CLINICAL_NOTES_LIST_URL = "".concat(BASE_URL, "/getClinicalNotes");
export var GET_LAB_REPORT_HISTORY_LIST_URL = "".concat(BASE_URL, "/patientLabReport");
export var GET_CHAT_TOKEN = "".concat(BASE_URL, "/getTwilioChatToken");
export var MARK_MESSAGE_READ = "".concat(BASE_URL, "/markMessageAsRead");
export var GET_CALL_TOKEN = "".concat(BASE_URL, "/getVideoCallToken");
export var ADD_PARTICIPANT = "".concat(BASE_URL, "/addParticipant");
export var END_CALL_URL = "".concat(BASE_URL, "/destroyRoom");
export var UPCOMING_CALLS_URL = "".concat(BASE_URL, "/getDoctorScheduledCalls");
export var STRIPE_PAYMENTS_URL = "".concat(BASE_URL, "/stripe/paymentLink/list");
export var STRIPE_CREATE_PAYMENT_URL = "".concat(BASE_URL, "/stripe/paymentLink/create");
export var STRIPE_DELETE_PAYMENT_URL = "".concat(BASE_URL, "/stripe/paymentLink/delete");
export var ACCOUNT_SETUP_URL = "".concat(BASE_URL, "/stripe/accountLink/create");
export var TREND_NOTIFICATION_LISTING = "".concat(BASE_URL, "/trendNotification");
export var DATA_DELAY_NOTIFICATION_LISTING = "".concat(BASE_URL, "/dataDelayNotification");
export var GET_ALL_UNREAD_NOTIFICATIONS_COUNT = "".concat(BASE_URL, "/doctorUnreadNotificationCount");
export var MARK_NOTIFICATION_READ = "".concat(BASE_URL, "/markDoctorNotificationRead");
export var GET_UNREAD_MESSAGES = "".concat(BASE_URL, "/getUnreadMessages");
export var GET_LAST_MONTH_EARNING = "".concat(BASE_URL, "/getLastMonthEarning");
export var GET_CUSTOMER_SATISFACTION_SCORE = "".concat(BASE_URL, "/getCustomerSatisfactionScore");
export var GET_TIME_SAVED = "".concat(BASE_URL, "/getTimeSaved");
export var GET_SUBSCRIPTION_MONTHLY_INCOME = "".concat(BASE_URL, "/getSubscriptionMonthlyIncome"); // Quickers Api URL:

export var QUICKERS_BASE_URL = "".concat(BMX_PLUS_API_URL, "api");
export var GET_BMX_PLUS_CHAT = "".concat(QUICKERS_BASE_URL, "/doctor/bot/chat/retrieve");
export var SEND_MESSAGE_BMX_PLUS_CHAT = "".concat(QUICKERS_BASE_URL, "/doctor/bot/chat");