import { ACCESS_TOKEN } from "../constants";
import { getItem, setItem, removeItem, clearStorage } from "./helper";
export var getAccessToken = function getAccessToken() {
  return getItem(ACCESS_TOKEN);
};
export var setToken = function setToken(accessToken) {
  setItem(ACCESS_TOKEN, JSON.stringify(accessToken));
};
export var removeToken = function removeToken() {
  removeItem(ACCESS_TOKEN);
  clearStorage();
};