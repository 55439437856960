export var BASE_URL = "/";
export var LOGIN_URL = "".concat(BASE_URL, "login");
export var RESET_PASSWORD_URL = "".concat(BASE_URL, "reset-password");
export var PROFILE_URL = "".concat(BASE_URL, "profile");
export var PATIENTS_URL = "".concat(BASE_URL, "patients");
export var DASHBOARD_URL = "".concat(BASE_URL, "dashboard");
export var BUSINESS_DASHBOARD_URL = "".concat(BASE_URL, "business-dashboard");
export var BMX_PLUS_URL = "".concat(BASE_URL, "bmx-plus");
export var ACCOUNT_AND_PAYMENTS_URL = "".concat(BASE_URL, "account-and-payments");
export var UPCOMING_CALLS = "".concat(BASE_URL, "upcoming-calls");
export var CAlENDLY_URL = "".concat(BASE_URL, "calendly");
export var NOTIFICATIONS = "".concat(BASE_URL, "notifications");
export var INVITE_CLIENT_URL = "".concat(BASE_URL, "invite-client");
export var PATIENT_INFORMATION_URL = "".concat(BASE_URL, "patient");
export var PLAN_UPDATE_URL = "".concat(PATIENT_INFORMATION_URL, "/update");
export var PATIENT_INFORMATION_HISTORY_URL = "".concat(PATIENT_INFORMATION_URL, "/history");
export var AUDIO_CALL_SCREEN_URL = "".concat(BASE_URL, "audio-call");
export var VIDEO_CALL_SCREEN_URL = "".concat(BASE_URL, "video-call");