import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { useChatClientContext } from "@src/context";

var useMessageAdded = function useMessageAdded() {
  var _useChatClientContext = useChatClientContext(),
      chatClient = _useChatClientContext.chatClient;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      messageAdded = _useState2[0],
      setMessageAdded = _useState2[1];

  useEffect(function () {
    var messageAddedHandler = function messageAddedHandler(message) {
      // console.log("messageAdded=>", message);
      setMessageAdded(message);
    };

    if (chatClient) {
      chatClient.on("messageAdded", messageAddedHandler);
    }

    return function () {
      chatClient === null || chatClient === void 0 ? void 0 : chatClient.off("messageAdded", messageAddedHandler);
    };
  }, [chatClient]);
  return {
    messageAdded: messageAdded
  };
};

export default useMessageAdded;