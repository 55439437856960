import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
export default function usePublications(participant) {
  var _useState = useState([]),
      _useState2 = _slicedToArray(_useState, 2),
      publications = _useState2[0],
      setPublications = _useState2[1];

  useEffect(function () {
    // Reset the publications when the 'participant' variable changes.
    setPublications(Array.from(participant.tracks.values()));

    var publicationAdded = function publicationAdded(publication) {
      return setPublications(function (prevPublications) {
        return [].concat(_toConsumableArray(prevPublications), [publication]);
      });
    };

    var publicationRemoved = function publicationRemoved(publication) {
      return setPublications(function (prevPublications) {
        return prevPublications.filter(function (p) {
          return p !== publication;
        });
      });
    };

    participant.on("trackPublished", publicationAdded);
    participant.on("trackUnpublished", publicationRemoved);
    return function () {
      participant.off("trackPublished", publicationAdded);
      participant.off("trackUnpublished", publicationRemoved);
    };
  }, [participant]);
  return publications;
}