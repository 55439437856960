import { useContext } from "react";
import { MetaContext } from "./index";
export default function useMetaContext() {
  var context = useContext(MetaContext);

  if (!context) {
    throw new Error("useMetaContext must be used within a MetaProvider");
  }

  return context;
}