import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
export default function useTrack(publication) {
  var _useState = useState(publication && publication.track),
      _useState2 = _slicedToArray(_useState, 2),
      track = _useState2[0],
      setTrack = _useState2[1];

  useEffect(function () {
    // Reset the track when the 'publication' variable changes.
    setTrack(publication && publication.track);

    if (publication) {
      var removeTrack = function removeTrack() {
        return setTrack(null);
      };

      publication.on("subscribed", setTrack);
      publication.on("unsubscribed", removeTrack);
      return function () {
        publication.off("subscribed", setTrack);
        publication.off("unsubscribed", removeTrack);
      };
    }
  }, [publication]);
  return track;
}