import { useContext } from "react";
import { PatientsContext } from "./index";
export default function usePatientsContext() {
  var context = useContext(PatientsContext);

  if (!context) {
    throw new Error("usePatientsContext must be used within a PatientsProvider");
  }

  return context;
}