import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { useVideoContext } from "@src/context";
export default function useRoomState() {
  var _useVideoContext = useVideoContext(),
      room = _useVideoContext.room;

  var _useState = useState("disconnected"),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  useEffect(function () {
    if (room) {
      var setRoomState = function setRoomState() {
        return setState(room.state);
      };

      setRoomState();
      room.on("disconnected", setRoomState).on("reconnected", setRoomState).on("reconnecting", setRoomState);
      return function () {
        room.off("disconnected", setRoomState).off("reconnected", setRoomState).off("reconnecting", setRoomState);
      };
    }
  }, [room]);
  return state;
}