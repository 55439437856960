import { useEffect, useRef } from "react";

var AudioTrack = function AudioTrack(_ref) {
  var track = _ref.track;
  // const { activeSinkId } = useVideoContext();
  var audioEl = useRef();
  useEffect(function () {
    audioEl.current = track.attach();
    audioEl.current.setAttribute("data-cy-audio-track-name", track.name);
    document.body.appendChild(audioEl.current);
    return function () {
      return track.detach().forEach(function (el) {
        el.remove(); // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
        // See: https://github.com/twilio/twilio-video.js/issues/1528

        el.srcObject = null;
      });
    };
  }, [track]); // useEffect(() => {
  //   audioEl.current?.setSinkId?.(activeSinkId);
  // }, [activeSinkId]);

  return null;
};

export default AudioTrack;