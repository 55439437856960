import { useEffect } from "react";
export default function useHandleRoomDisconnection(room, onError, removeLocalAudioTrack, removeLocalVideoTrack) {
  useEffect(function () {
    if (room) {
      var onDisconnected = function onDisconnected(_, error) {
        if (error) {
          onError(error);
        }

        removeLocalAudioTrack();
        removeLocalVideoTrack();
      };

      room.on("disconnected", onDisconnected);
      return function () {
        room.off("disconnected", onDisconnected);
      };
    }
  }, [room, onError, removeLocalAudioTrack, removeLocalVideoTrack]);
}