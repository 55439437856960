import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useRef, useState } from "react";
import { CHAT_MESSAGES_COUNT } from "@src/constants";
import { useChatContext } from "@src/context";

var useConversation = function useConversation() {
  var _useChatContext = useChatContext(),
      conversation = _useChatContext.conversation,
      setHasUnreadMessages = _useChatContext.setHasUnreadMessages,
      isChatWindowOpen = _useChatContext.isChatWindowOpen,
      markMessageRead = _useChatContext.markMessageRead;

  var _useState = useState([]),
      _useState2 = _slicedToArray(_useState, 2),
      messages = _useState2[0],
      setMessages = _useState2[1];

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      newMessageAdded = _useState4[0],
      setNewMessageAdded = _useState4[1];

  var _useState5 = useState(null),
      _useState6 = _slicedToArray(_useState5, 2),
      messagePagination = _useState6[0],
      setMessagesPagination = _useState6[1];

  var _useState7 = useState(false),
      _useState8 = _slicedToArray(_useState7, 2),
      loadingPrevPage = _useState8[0],
      setLoadingPrevPage = _useState8[1];

  var _useState9 = useState(true),
      _useState10 = _slicedToArray(_useState9, 2),
      loadingMessages = _useState10[0],
      setLoadingMessages = _useState10[1];

  var _useState11 = useState(""),
      _useState12 = _slicedToArray(_useState11, 2),
      errorLoadingPrevPage = _useState12[0],
      setErrorLoadingPrevPage = _useState12[1];

  var isChatWindowOpenRef = useRef(false);

  var getMessagesPrevPage = function getMessagesPrevPage() {
    if (messagePagination) {
      setLoadingPrevPage(true);
      messagePagination.prevPage().then(function (newMessages) {
        setMessagesPagination(newMessages);
        setMessages(function (oldMessages) {
          return [].concat(_toConsumableArray(newMessages.items), _toConsumableArray(oldMessages));
        });
      }).catch(function (error) {
        return setErrorLoadingPrevPage(error);
      }).finally(function () {
        return setLoadingPrevPage(false);
      });
    }
  };

  useEffect(function () {
    if (conversation) {
      // setLoadingMessages(true);
      var handleMessageAdded = function handleMessageAdded(message) {
        setMessages(function (oldMessages) {
          return [].concat(_toConsumableArray(oldMessages), [message]);
        });
        setNewMessageAdded(message);
        markMessageRead(message.index);
      };

      conversation.getMessages(CHAT_MESSAGES_COUNT).then(function (newMessages) {
        setMessagesPagination(newMessages);
        setMessages(newMessages.items);
        setLoadingMessages(false);
        markMessageRead(conversation.lastMessage.index);
      }).catch(function (error) {
        return void 0;
      });
      conversation.on("messageAdded", handleMessageAdded);
      return function () {
        conversation.off("messageAdded", handleMessageAdded);
      };
    }
  }, [conversation]);
  useEffect(function () {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && newMessageAdded) {
      if (newMessageAdded.attributes.call_status) return;
      setHasUnreadMessages(true);
    }
  }, [newMessageAdded]);
  useEffect(function () {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);
  return {
    messages: messages,
    messagePagination: messagePagination,
    getMessagesPrevPage: getMessagesPrevPage,
    loadingPrevPage: loadingPrevPage,
    errorLoadingPrevPage: errorLoadingPrevPage,
    loadingMessages: loadingMessages,
    newMessageAdded: newMessageAdded
  };
};

export default useConversation;