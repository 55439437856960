import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from "react";

var useWaitingDots = function useWaitingDots() {
  var _useState = useState("."),
      _useState2 = _slicedToArray(_useState, 2),
      dotCount = _useState2[0],
      setDotCount = _useState2[1];

  useEffect(function () {
    var interval = setInterval(function () {
      if (dotCount.length === 3) {
        setDotCount(".");
      } else {
        // eslint-disable-next-line no-return-assign
        setDotCount(function (prevState) {
          return prevState += ".";
        });
      }
    }, 1000);
    return function () {
      clearInterval(interval);
    };
  }, [dotCount]);
  return {
    dotCount: dotCount
  };
};

export default useWaitingDots;