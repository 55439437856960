import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
export default function useParticipantIsReconnecting(participant) {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isReconnecting = _useState2[0],
      setIsReconnecting = _useState2[1];

  useEffect(function () {
    var handleReconnecting = function handleReconnecting() {
      return setIsReconnecting(true);
    };

    var handleReconnected = function handleReconnected() {
      return setIsReconnecting(false);
    };

    handleReconnected(); // Reset state when there is a new participant

    participant.on("reconnecting", handleReconnecting);
    participant.on("reconnected", handleReconnected);
    return function () {
      participant.off("reconnecting", handleReconnecting);
      participant.off("reconnected", handleReconnected);
    };
  }, [participant]);
  return isReconnecting;
}