import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from "react";

/*
 * This hook allows components to reliably use the 'mediaStreamTrack' property of
 * an AudioTrack or a VideoTrack. Whenever 'localTrack.restart(...)' is called, it
 * will replace the mediaStreamTrack property of the localTrack, but the localTrack
 * object will stay the same. Therefore this hook is needed in order for components
 * to rerender in response to the mediaStreamTrack changing.
 */
export default function useMediaStreamTrack(track) {
  var _useState = useState(track === null || track === void 0 ? void 0 : track.mediaStreamTrack),
      _useState2 = _slicedToArray(_useState, 2),
      mediaStreamTrack = _useState2[0],
      setMediaStreamTrack = _useState2[1];

  useEffect(function () {
    setMediaStreamTrack(track === null || track === void 0 ? void 0 : track.mediaStreamTrack);

    if (track) {
      var handleStarted = function handleStarted() {
        return setMediaStreamTrack(track.mediaStreamTrack);
      };

      track.on("started", handleStarted);
      return function () {
        track.off("started", handleStarted);
      };
    }
  }, [track]);
  return mediaStreamTrack;
}