import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from "react";
import { getDeviceInfo } from "@utils/videoHelpers"; // This returns the type of the value that is returned by a promise resolution

export default function useDevices() {
  var _useState = useState({
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
    hasAudioInputDevices: false,
    hasVideoInputDevices: false
  }),
      _useState2 = _slicedToArray(_useState, 2),
      deviceInfo = _useState2[0],
      setDeviceInfo = _useState2[1];

  useEffect(function () {
    var getDevices = function getDevices() {
      return getDeviceInfo().then(function (devices) {
        return setDeviceInfo(devices);
      });
    };

    navigator.mediaDevices.addEventListener("devicechange", getDevices);
    getDevices();
    return function () {
      navigator.mediaDevices.removeEventListener("devicechange", getDevices);
    };
  }, []);
  return deviceInfo;
}